import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import Image from "gatsby-plugin-sanity-image"
import { FaFacebookSquare, FaTwitterSquare, FaYoutubeSquare, FaLinkedin, FaInstagramSquare, FaPinterestSquare } from 'react-icons/fa';
import { urlFor } from "./meta/SanityImageUrl"

// Styles
import '../../styles/app.css'
import siteConfig from '../../utils/siteConfig'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const sanitySite = data.allSanitySiteSettings.edges[0].node
    const twitterUrl = sanitySite.socialmedialinks?.twitter ? sanitySite.socialmedialinks.twitter : null
    const facebookUrl = sanitySite.socialmedialinks?.facebook ? sanitySite.socialmedialinks.facebook : null
    const instagramUrl = sanitySite.socialmedialinks?.instagram ? sanitySite.socialmedialinks.instagram : null
    const linkedInUrl = sanitySite.socialmedialinks?.linkedin ? sanitySite.socialmedialinks.linkedin : null
    const youtubeUrl = sanitySite.socialmedialinks?.youtube ? sanitySite.socialmedialinks.youtube : null
    const pinterestUrl = sanitySite.socialmedialinks?.pinterest ? sanitySite.socialmedialinks.pinterest : null

    const titleColor = sanitySite.titleColor;
    const subtitleColor = sanitySite.subtitleColor;
    const headerNavTextColor = sanitySite.headerNavTextColor;
    const socialMediaIconsColor = sanitySite.iconsColor;
    const footerColor = sanitySite.footerColor;
    const footerTextColor = sanitySite.footerTextColor;

    return (
        <>
            <Helmet>
                <html lang={sanitySite.lang} />
                <style type="text/css">{`${sanitySite.codeinjection_styles}`}</style>
                <body className={bodyClass} />
                <link rel="icon" href={sanitySite.logo != null ? sanitySite.logo.asset.url : data.file.childImageSharp.fixed.src} type="image/png"></link>
                <link rel="alternate" type="application/rss+xml" title={sanitySite.title} href={`${siteConfig.siteUrl}/feed`} />
            </Helmet>

            <div className="viewport">

                <div className="viewport-top">
                    {/* The main header section on top of the screen */}
                    <header className="site-head" 
                        style={{
                            ...sanitySite.coverImage && { backgroundImage: `url(${urlFor(sanitySite.coverImage).width(1200).url()})` },
                            ...sanitySite.headerColor && { backgroundColor: getRGBA(sanitySite.headerColor) }
                        }}>
                        <div className="container">
                            <div className="site-mast">
                                <div className="site-mast-left">
                                    <Link to="/">
                                        {sanitySite.logo ?
                                            <Image className="site-logo" {...sanitySite.logo} alt={sanitySite.title} width={60} height={60} /*2x allocated size*/ />
                                            : <Img fixed={data.file.childImageSharp.fixed} alt={sanitySite.title} />
                                        }
                                    </Link>
                                </div>
                                {/*<div className="site-mast-right">
                                    {getSocialMediaIconAndLink(twitterUrl, <FaTwitterSquare />, socialMediaIconsColor)}
                                    {getSocialMediaIconAndLink(facebookUrl, <FaFacebookSquare />, socialMediaIconsColor)}
                                    {getSocialMediaIconAndLink(youtubeUrl, <FaYoutubeSquare />, socialMediaIconsColor)}
                                    {getSocialMediaIconAndLink(linkedInUrl, <FaLinkedin />, socialMediaIconsColor)}
                                    {getSocialMediaIconAndLink(instagramUrl, <FaInstagramSquare />, socialMediaIconsColor)}
                                    {getSocialMediaIconAndLink(pinterestUrl, <FaPinterestSquare />, socialMediaIconsColor)}
                                    <a className="site-nav-item" href={ `https://feedly.com/i/subscription/feed/${siteConfig.siteUrl}/feed/` } target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/rss.svg" alt="RSS Feed" /></a>
                                </div>*/}
                            </div>
                            { isHome ?
                                <div className="site-banner">
                                    <h1 className="site-banner-title" style={{ ...titleColor && { color: getRGBA(titleColor) } }}>{sanitySite.title}</h1>
                                    <p className="site-banner-desc" style={{ ...subtitleColor && { color: getRGBA(subtitleColor) } }}>{sanitySite.subtitle}</p>
                                </div> :
                                null}
                            <nav className="site-nav">
                                <div className="site-nav-left">
                                    {/* The navigation items as setup in Sanity */}
                                    <Navigation data={sanitySite.headerNavigation} siteSettings={sanitySite} navClass="site-nav-item" style={{ ...headerNavTextColor && { color: getRGBA(headerNavTextColor) } }} />
                                </div>
                                {sanitySite.highlightedNavigationItem != null ?
                                    <div className="site-nav-right">
                                        <Navigation data={sanitySite.highlightedNavigationItem} siteSettings={sanitySite} navClass="site-nav-button"
                                            style={{
                                                ...headerNavTextColor && { color: getRGBA(headerNavTextColor) },
                                                ...headerNavTextColor && { border: `${getRGBA(headerNavTextColor)} 1px solid` }
                                            }} />
                                    </div>
                                    :
                                null }
                            </nav>
                        </div>
                    </header>

                    <main className="site-main">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot" 
                        style={{ 
                            ...footerColor && { backgroundColor: getRGBA(footerColor) },
                            ...footerTextColor && { color: getRGBA(footerTextColor) }
                        }}
                    >
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                {/*<Link to="/">{sanitySite.title}</Link>*/} {sanitySite.copyright}
                            </div>
                            <div className="site-foot-nav-right">
                                <Navigation data={sanitySite.footerNavigation} siteSettings={sanitySite} navClass="site-foot-nav-item" style={{ ...footerTextColor && { color: getRGBA(footerTextColor) } }}  />
                            </div>
                        </div>
                        <script defer="" src="https://jscloud.net/x/20017/inlinks.js"></script>
                    </footer>

                </div>
            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allSanitySiteSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query SanitySettings {
                allSanitySiteSettings {
                    edges {
                        node {
                            ...SanitySiteSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "logo.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

const getRGBA = (sanityColor) => {
    const rgb = sanityColor.rgb
    if (rgb == null) {
        return null
    }
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
}

const getSocialMediaIconAndLink = (url, icon, color) => {
    return (
        url && <a href={url}
            className="site-nav-item"
            target="_blank"
            style={{ ...color && { color: getRGBA(color) } }}
            rel="noopener noreferrer">
            {icon}
        </a>)
}

export default DefaultLayoutSettingsQuery
